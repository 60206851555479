import { FC } from "react";

export const ZoomSvg: FC<{ size?: "default" | "sm" }> = ({
    size = "default",
}) => {
    let svgSize = "40px";
    if (size === "sm") {
        svgSize = "18px";
    }

    return (
        <svg
            fill="none"
            height={svgSize}
            width={svgSize}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 50.667 50.667"
        >
            <path
                d="M25.333 50.667c13.992 0 25.334-11.343 25.334-25.334S39.325 0 25.333 0 0 11.342 0 25.333s11.342 25.334 25.333 25.334z"
            />
            <path
                clipRule="evenodd"
                d="M14.866 32.574h16.755V20.288c0-1.851-1.5-3.351-3.351-3.351H11.515v12.286c0 1.851 1.5 3.351 3.351 3.351zm18.988-4.467l6.702 4.467V16.937l-6.701 4.468z"
                fill="#fff"
                fillRule="evenodd"
            />
        </svg>
    );
};
